import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Card } from 'react-bootstrap';
import { LoaderButton } from './LoaderButton';
import { sendFeedback, verifyGoogleReCaptcha } from '../assets/scripts/feedback';
import { useToggle } from '../assets/scripts/hooksLib';
import { onError } from '../assets/scripts/errorLib';
// import '../assets/styles/app.scss';
// import '../assets/styles/index.scss';
// import '../assets/styles/main.scss';
import '../assets/styles/feedback.scss';

/**
 * Global Stitchz.net footer
 * @returns {HTMLElement} html of the global footer
 */
export default function Feedback() {
    // const router = useRouter();
    const [feedback, setFeedback] = useState({});
    const [isLoading, setIsLoading] = useToggle(false); // eslint-disable-line no-unused-vars
    const [failure, setFailure] = useState();
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // load Google reCAPTCHA
    useEffect(() => {
        // dynamically add the Google reCAPTCHA JS to the page
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LeZo20aAAAAAPJsjooWFn2NGw0ipnVmaGDrUzQA';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        // clean up the Stripe JS script when we leave
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // /**
    //  * Returns true if the feedback object has been completely filled out
    //  * @returns {boolean} true or false
    //  */
    // const validate = () => {
    //     return feedback &&
    //         feedback.FullName && feedback.FullName.length > 3 &&
    //         feedback.Email && feedback.Email.length > 3 &&
    //         feedback.Enquiry && feedback.Enquiry.length > 3;
    // };

    /**
     * Track each key press to update the feedback state
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChange = (e) => {
        const field = e.target.id,
            val = e.target.value;

        if (field === 'FullName') {
            if (val.length <= 2) {
                setErrors({...errors, FullName: 'Please enter your full name'});
            } else {
                setErrors(prevState => {
                    const state = { ...prevState };
                    // eslint-disable-next-line security/detect-object-injection
                    state[field] = undefined;
                    return state;
                });
            }
        }

        if (field === 'Email') {
            if(val.length <= 2) {
                setErrors({...errors, Email: 'Please enter your email address.'});
            } else {
                setErrors(prevState => {
                    const state = { ...prevState };
                    // eslint-disable-next-line security/detect-object-injection
                    state[field] = undefined;
                    return state;
                });
            }
        }

        setFeedback(feedback => {
            return { ...feedback, [field]: val }
        });
    };

    /**
     * Verify the given reCAPTCHA is valid
     * @param {string} token recaptcha token to verify
     * @returns {JSON} json object of response
     */
    const verifyCaptcha = async (token) => {
        const { status, message, body } = await verifyGoogleReCaptcha({ code: token });

        if (status !== 'OK') {
            onError(message);
        } else {
            return body;
        }
    };

    /**
     * Sends the Q&A and/or feedback
     * @param {MouseEvent} e form submit
     * @returns {void}
     */
    const onSubmit = async (e) => {
        if (e)
            e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            return;
        }

        setIsLoading(true);

        window.grecaptcha.ready(async () => {
            window.grecaptcha.execute('6LeZo20aAAAAAPJsjooWFn2NGw0ipnVmaGDrUzQA', { action: 'submit' })
                .then(async token => {
                    try {
                        const gReResponse = JSON.parse(await verifyCaptcha(token));

                        // console.log(gReResponse);

                        if (!gReResponse.success) {
                            const errors = gReResponse['error-codes'].join();

                            setFailure(errors);
                        } else {

                            try {
                                const { status, message } = await sendFeedback(feedback)
                                    .catch(err => {
                                        return {
                                            status: 'failed',
                                            message: err.response && err.response.data ? err.response.data.message : err.message
                                        };
                                    });

                                if (status !== 'OK') {
                                    onError(message);
                                } else {
                                    setValidated(true);
                                    // redirect to thank you page
                                    // router.push('/thankyou');
                                    // scrollTop();
                                    location.href = '/contactus.html?thankyou=1'
                                }
                            } catch (err) {
                                onError(err);
                            } finally {
                                // setIsLoading(false);
                            }
                        }
                    } catch (err) {
                        console.error(err);
                        setFailure('There was an error gathering your feedback.');
                    }

                    return false;
                });
        });
    };

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit} className='footer-form'>
            <Row>
                <Form.Group as={Col} md={9} controlId='FullName'>
                    <Form.Control
                        type='text'
                        required
                        placeholder='Full Name'
                        title='Full Name'
                        onChange={onChange}
                        isInvalid={!!errors.FullName}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.FullName}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md={9} controlId='Email'>
                    <Form.Control
                        type='email'
                        required={true}
                        placeholder='Email'
                        title='Email'
                        onChange={onChange}
                        isInvalid={!!errors.Email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.Email}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md={9} controlId='Enquiry'>
                    <Form.Control
                        as='textarea'
                        required={true}
                        placeholder='Message'
                        onChange={onChange}
                    />
                    <Form.Control.Feedback type="invalid">
                    Please enter your feedback or enquiry.
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row>
                <Col md={9} >
                    <div className='g-recaptcha' />
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    <LoaderButton
                        className='submit'
                        // disabled={!validate()}
                        isLoading={isLoading}
                        // onClick={onSubmit}
                        type='submit'
                    >
                        Send
                    </LoaderButton>
                </Col>
            </Row>
            <Row>
                {failure ?
                    <Card
                        bg='danger'
                        text='white'
                        className='mb-2'
                    >
                        <Card.Body>
                            <Card.Title>Error Sending Feedback</Card.Title>
                            <Card.Text>
                                {failure}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    :
                    ''
                }
            </Row>
        </Form>
    );
}
